import '../css/loadingScreen.css'
const LoadingScreen = () => {
    return (
        <div className="loadingContainer">
            <div class="text-container">
                <h1>ispaj</h1>
            </div>
        </div>
    )
}
export default LoadingScreen